import { Loader } from '@react-three/drei';

const ContainerStyles = {background: '#F9DDDD', display:'flex', justifyContent: 'center'};
const InnerStyles = {display:'flex',  height:'8pt', width: '100pt', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'};
const BarStyles = {backgroundColor:'#8C2373', height: '100%'};
const DataStyles = {color:'black', fontSize: 'inherit', top: '14pt', fontFamily: 'inherit'};


export default function StyledLoader()
{
    return <Loader 
    dataInterpolation={(p) => `Bitte warten`}
    containerStyles={ContainerStyles}
    innerStyles={InnerStyles}
    barStyles={BarStyles}
    dataStyles={DataStyles}/>
}
