import * as THREE from 'three'


const texture = new THREE.TextureLoader(
  './images/water.jpg',
  ( texture ) => {
		console.log("loaded texture");
		AnimatedMaterial.uniforms.uTexture.value = texture
	},)

export const AnimatedMaterial = new THREE.RawShaderMaterial({
  uniforms:
    {
        uTime: { value: 0 },
        uTexture: { value: texture }
    },
  vertexShader: `
      uniform mat4 projectionMatrix;
      uniform mat4 viewMatrix;
      uniform mat4 modelMatrix;

      attribute vec3 position;
      attribute vec2 uv;

      varying vec2 vUv;

      void main()
      {
          gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
          vUv = uv;
      }
  `,
  fragmentShader: `
      precision mediump float;

      uniform vec3 uColor;
      uniform sampler2D uTexture;
      uniform float uTime;
      
      varying vec2 vUv;
      
      void main()
      {
          vec4 textureColor = texture2D(uTexture, vUv);
          gl_FragColor = textureColor + uTime;
      }
  `
})