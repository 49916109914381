import * as THREE from 'three'
import React, { useEffect } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber'

const TextureSky = ({ viewpoint }) => {

    const { scene } = useThree()
    const texture = useLoader(THREE.TextureLoader, './images/skybox.jpg')

    texture.mapping = THREE.EquirectangularReflectionMapping

    texture.colorSpace = THREE.SRGBColorSpace
    scene.background = texture

    useEffect(() => {
       
      }, []);


    return (
      null
    );
};

export default TextureSky;