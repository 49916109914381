import { animated, useSpring } from '@react-spring/web';
import { useState } from 'react';

import MapImage from "/images/map.jpg";
import SVGIconClose from "/ui/close.svg";
import SVGIconHamburger from "/ui/compass.svg";



const mapPositions = [
    [12, 86],
    [12, 77],
    [37, 54],
    [26, 63]
]

export default function Menu({ viewpoint }) {
    const [menuOn, setMenuOn] = useState(false);

    const [springs, api] = useSpring(() => ({
        from: { transform: 'translateY(0%)', opacity: 0 },
    }))

    const toggleMenuOn = () => {
        if(!menuOn){
            setMenuOn(true)
           api.start({
            from: {
                transform: 'translateY(100%) rotate(90deg)', opacity: 0
            },
            to: {
                transform: 'translateY(0%) rotate(0)', opacity: 1
            }
        })
        } else {
            api.start({
                from: {
                    transform: 'translateY(0%)', opacity: 1
                },
                to: {
                    transform: 'translateY(100%)', opacity: 0
                },
                onResolve: () => {setMenuOn(false)}
            })
        }
    }

    return <>
        {!menuOn ? <img src={SVGIconHamburger} className="spinning" alt="Menu-Button" onClick={toggleMenuOn} style={{ width: '42pt', position: 'fixed', bottom: '20pt', left: '20pt', zIndex: '1000' }} />
            : <img src={SVGIconClose} alt="Menu-Button" onClick={toggleMenuOn} style={{ width: '35pt', position: 'fixed', bottom: '20pt', left: '20pt', zIndex: '1000' }} />
        }

        {menuOn && <div className='overlay' style={{ background: 'rgba(255, 255, 255, 0.5' }}>
            <animated.div className='map-container' style={springs}>
                <img src={MapImage} className='map-image' onClick={() => { toggleMenuOn() }} />
                <div className='map-dot' style={{ left: mapPositions[viewpoint][0] + '%', top: mapPositions[viewpoint][1] + '%' }}></div>
            </animated.div>
        </div>}
    </>
}
