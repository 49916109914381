import { useFrame } from '@react-three/fiber'
import React, { useRef } from 'react'


const DirectionLightWithShadows = () => {

    const mainLight = useRef();
  



    useFrame((_, delta) => {
        //mainLight.rotateOnAxis(new THREE.Vector3(0, 1, 0), delta * 10)
        
      })
  

    return (
        <directionalLight
            castShadow
            position={[-5, 3, 2]}
            intensity={3}
            shadow-camera-near={0.1}
            shadow-bias={-0.003}
            shadow-camera-far={300}
            shadow-camera-left={-100}
            shadow-camera-right={100}
            shadow-camera-top={50}
            shadow-camera-bottom={-50}
            shadow-mapsize={[2048, 2048]}
            ref={mainLight} />
    )
}

export default DirectionLightWithShadows
