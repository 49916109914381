import { useFrame } from '@react-three/fiber';
import { useEffect } from 'react';

let frameCount;

const Rendering = ({ viewpoint, setWaitForFrames }) => {
   

    useFrame((state, delta) => {
        frameCount++;
        if(frameCount == 10){
            setWaitForFrames(false)
        }
    })

    useEffect(() => {
        frameCount = 0;
        setWaitForFrames(true)
      }, [viewpoint]);

    return <></>
};

export default Rendering;