
import { useSpring } from '@react-spring/web';
import { DeviceOrientationControls } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';


const viewpointAlpha = [0, 0, 0]

export function DeviceOrientationControlsCustom({viewpoint}) {

  const deviceMotionControls = useRef();
  const [initialRotationOffset, setInitialRotationOffset] = useState();
  const [rotationOffsetInitialized, setRotationOffsetInitialized] = useState(false)

  const [springs, api] = useSpring(() => ({
    from: { viewpointAlpha: 0 },
  }))

  const [startOffset, setStartOffset] = useState(0);


  useFrame(() => {
    let currentAlpha = deviceMotionControls.current.deviceOrientation.alpha;
    if(!rotationOffsetInitialized && currentAlpha != 0){
      console.log(deviceMotionControls.current.screenOrientation);
      let offset = currentAlpha - deviceMotionControls.current.screenOrientation;
      setStartOffset(offset);
      console.log("Start rotation offset: " + offset);
      setRotationOffsetInitialized(true);
    }
    updateAlpha();
  });

  useEffect(() => {
    api.start({
      from: {
        viewpointAlpha: 0
      },
      to: {
        viewpointAlpha: viewpointAlpha[viewpoint]
      }
    })
  }, [viewpoint]);
  

  const updateAlpha = () => {
    const initialRotationOffsetInRadians = 6.2831 * (startOffset) / 360;
    deviceMotionControls.current.alphaOffset = -initialRotationOffsetInRadians;
  }
  
  return <DeviceOrientationControls ref={deviceMotionControls}/>


}

export default DeviceOrientationControlsCustom;