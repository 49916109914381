
import { FirstPersonControls } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';


const FirstPersonControlsCustom = ({ viewpoint, setResetForward }) => {

    const { camera } = useThree();


    useEffect(() => {
        setResetForward(() => { "Reset forward" });
    }, []);

    return <FirstPersonControls
        movementSpeed={0}
        lookSpeed={0.3}
        constrainVertical={true}
        verticalMin={Math.PI * 0.75}
        verticalMax={Math.PI * 0.25} />

};

export default FirstPersonControlsCustom;

