import { Html } from '@react-three/drei';
import { useState, useEffect } from 'react'
import { useSpring, animated, config, easings } from '@react-spring/three'
import { useThree, useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import SVGIcon from "/ui/arrow-line.svg";


const ViewpointHTMLButton = ({ point, text = "Wegpunkt", visibleFromIndices = [0], viewPointIndex, setViewpoint, normalizedTransition, setNormalizedTransition, viewpoint, transitionSpeed = 3000, htmlElementOffset = [0, 0, 0], disabled }) => {
  const [open, setOpen] = useState(false);
  const { camera } = useThree();

  const [springs, api] = useSpring(() => ({
    from: {
      position: [100, 100, 100],
      normalizedValue: 0
    },
  }))


  useEffect(() => {
    if (viewpoint != viewPointIndex)
      return
    const startPosition = [camera.position.x, camera.position.y, camera.position.z];
    setNormalizedTransition(0);
    console.log(point);
    api.start({
      from: {
        position: startPosition,
        normalizedValue: 0,
      },
      to: {
        position: point,
        normalizedValue: 1
      },
      config: {
        duration: transitionSpeed, // Duration in milliseconds
        easing: easings.easeInOutQuart,
      },
      onChange: (animatedValue) => {
        let cameraYPosition = animatedValue.value.position[1];
        if (viewpoint >= 2) {
          let flyOffset = (0.5 - Math.abs(animatedValue.value.normalizedValue - 0.5)) * 2;
          flyOffset = Math.pow(flyOffset, 0.2) * 35;
          cameraYPosition += flyOffset;
        }
        camera.position.set(animatedValue.value.position[0], cameraYPosition, animatedValue.value.position[2])
        setNormalizedTransition(animatedValue.value.normalizedValue);
      },
      onResolve: () => {
        setNormalizedTransition(1);
      }
    })
  }, [viewpoint]);

  return (visibleFromIndices.includes(viewpoint) && normalizedTransition == 1 && !disabled) && 
  <Html zIndexRange={[100, 0]} position={[point[0] + htmlElementOffset[0], point[1] + htmlElementOffset[1], point[2] + htmlElementOffset[2]]} center={true}>
    <div className='bubble center' ><p>{text}</p>
      <button className='rounded-button' onClick={(e) => {setViewpoint(viewPointIndex)}}>
      <img src={SVGIcon} alt="Continue" style={{ width: '25pt', position: 'relative', top: '4pt' }}/>
      </button>
    </div>
  </Html>
};

export default ViewpointHTMLButton;