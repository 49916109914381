import { useFrame, useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import * as THREE from 'three';


let time = 0;
const cameraDefaultPosition = [39, 7, -33]
const xSpeed = 0.3;
const ySpeed = 0.5;
const xRadius = 2;
const yRadius = 2;

function AutoCamera() {

  const { camera } = useThree();


    useEffect(() => {
    }, []);


    useFrame((_, delta) => {
      time += delta
      let xPos = Math.sin(time * xSpeed) * xRadius + cameraDefaultPosition[0];
      let yPos = Math.sin(time * ySpeed) * yRadius + cameraDefaultPosition[1];
      camera.position.set(xPos, yPos, cameraDefaultPosition[2])
      camera.lookAt(new THREE.Vector3(44.8, 8, -39.7))
    })

  

  return null
}

export default AutoCamera
