import { Html } from '@react-three/drei'
import { useFrame, useLoader } from '@react-three/fiber'
import React, { useLayoutEffect, useState } from 'react'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { AnimatedMaterial } from './AnimatedMaterial'

import * as THREE from 'three'

let waterMaterial;

export default function LoadModel({ sceneURL, visible = true }) {

    const [sceneReady, setSceneReady] = useState(false);

    useFrame((state, delta, xrFrame) => {
        if (waterMaterial == null)
            return;
        let speed = 0.007;
        let newOffset = waterMaterial.map.offset.x + delta * speed;
        waterMaterial.map.offset = new THREE.Vector2(newOffset, newOffset);
    })

    const model = useLoader(GLTFLoader, sceneURL, (loader) => {
        console.log(`Loading ${sceneURL}`)
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('./draco/')
        loader.setDRACOLoader(dracoLoader)

    })

    

    useLayoutEffect(() => {
        if (sceneReady)
            return;
        async function prepareScene(){
            model.scene.traverse((obj) => {
                if (obj.isMesh) {
    
                    obj.material.metalness = 0;
    
                    if (obj.name.includes("House")) {
                        //obj.material.transparent = true;
                        //obj.material.opacity = 0.7
                    } else if (obj.name.includes("Turm")) {
                        if (obj.name.includes("Turm_1")) {
                            obj.material.color = new THREE.Color('#f5e9da');
                        } else {
    
                            obj.material.color = new THREE.Color('#756f6d');
                        }
    
                        //obj.material = replaceWithFlatMaterial(obj.material);
                    } else if (obj.material.name.includes("Grass")) {
                        obj.material.color.set(1.3, 1.5, 1.1);
                        /*if(obj.material.map)
                            obj.material.map.repeat.set( 10, 10 );*/
                    } else if (obj.name.includes("Grass")) {
                        //obj.material.color.set(1.5, 1.5, 1);
                        /*if(obj.material.map)
                            obj.material.map.repeat.set( 10, 10 );*/
                    } else if (obj.name.includes("Tree")) {
                        obj.material = replaceWithFlatMaterial(obj.material);
                        //obj.material.color.set(2, 2, 2)
                    } else if (obj.name.includes("Wasser")) {
                        console.log("assign water to " + obj.name)
                        waterMaterial = obj.material;
                        //obj.material = replaceWithAnimatedMaterial(obj.material);
                    }
                }
            })
        }
        prepareScene();
        setSceneReady(true);
        //populateScene(model.scene)
    })

    const replaceWithFlatMaterial = (materialIn) => {
        var material = new THREE.MeshBasicMaterial({
            map: materialIn.map,
            alphaTest: 0.8,
            transparent: false,
            side: THREE.DoubleSide,
        });
        return material;
    }

    const replaceWithAnimatedMaterial = (materialIn) => {
        return AnimatedMaterial;
    }

    const populateScene = (scene) => {
        scene.traverse((obj) => {
            let newHtmlElement = React.createElement(Html,);
            console.log(newHtmlElement)
            htmlItems.push(newHtmlElement);
            obj.appendChild(newHtmlElement);
        })
    }


    return (sceneReady && visible) && <primitive object={model.scene} visible={visible}/>
}

