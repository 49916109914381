import { useGLTF } from '@react-three/drei';
import { StrictMode, Suspense, useState } from "react";
import DeviceMotion from './Components/DeviceMotion.jsx';
import OverlayMask from './LayoutComponents/OverlayMask.jsx';
import Scene from './Scene.jsx';


const towerGLTF = './models/turm-4.glb'
const sceneGLTF = ['./models/Bredella_Areal_Ausgangspunkt_v03_01.glb',
  './models/Bredella_Areal_Punk01_v09.glb',
  './models/Bredella_Areal_Punk02_v02.glb']

export default function App() {
  const [deviceMotionEnabled, setDeviceMotionEnabled] = useState(false);

  const [interactiveSceneActive, setInteractiveSceneActive] = useState(false);

  return <StrictMode>
    {!interactiveSceneActive ? <DeviceMotion
      deviceMotionEnabled={deviceMotionEnabled}
      setDeviceMotionEnabled={setDeviceMotionEnabled}
      setInteractiveSceneActive={setInteractiveSceneActive}
      fallback={null}
    /> : null}

    <Suspense>
      <Scene deviceMotionEnabled={deviceMotionEnabled}
        sceneURL={sceneGLTF}
        towerURL={towerGLTF}
        interactiveSceneActive={interactiveSceneActive} />

    </Suspense>
    <OverlayMask />
  </StrictMode>
}

sceneGLTF.map((url) => {
  useGLTF.preload(url)
}
)