import { Html } from '@react-three/drei';
import { useEffect, useState } from 'react';

import SVGIcon from "/ui/ellipsis.svg";

const SpeechBubbleHTML = ({ group, position, text = ["Hello!", "Hallo!"], visibleFromIndices = [0], viewpoint, currentBubble, setCurrentBubble }) => {
    const [open, setOpen] = useState(false);
    const [textIndex, setTextIndex] = useState(0);

    const selectThisViewpoint = () => {
        console.log(this);
        setViewpoint(viewPointIndex);
    }

    const buttonHandler = () => {
        if (textIndex < text.length - 1) {
            setTextIndex(textIndex + 1);
        } else {
            setTextIndex(0);
        }
    }

    useEffect(() => {
        if (currentBubble == group) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [currentBubble]);

    return <>{visibleFromIndices.includes(viewpoint) ?
        !open ?
            <Html zIndexRange={[10, 0]} position={[position[0], position[1] - 0.8, position[2]]} center={true}>
                <div className='bubbledot' onClick={() => { setCurrentBubble(group) }}>
                    <img src={SVGIcon} alt="Open Speech Bubble" style={{ width: '25pt', margin: 'auto' }} />
                </div></Html>

            :
            <Html zIndexRange={[100, 0]} position={position} center={true}>
                <div className='bubble center with-arrow' onClick={() => { setCurrentBubble(-1) }}><p> {Array.isArray(text) ? text[textIndex] : text}</p>
                    {Array.isArray(text) && <button className='rounded-button' onClick={(e) => buttonHandler()}>
                        <img src={SVGIcon} alt="Ellipsis" style={{ width: '25pt', position: 'relative', top: '-2.5pt' }} />
                    </button>}
                </div>

            </Html>
        : null}</>
};

export default SpeechBubbleHTML;