import { animated, useSpring } from '@react-spring/web';
import { useLayoutEffect } from 'react';
import Logo from "/images/implenia.jpg";
import Bredella from "/images/bredella-logotype.svg";



export default function DialogBox({ title1 = "Message", title2 = "", text = "Lorem Ipsum", buttonLabel = "", buttonHandler, additionalMessage = "" }) {

  const [springs, api] = useSpring(() => ({
    from: { transform: 'translateX(0%)', opacity: 0 },
  }))

  const urlParams = new URLSearchParams(window.location.search);

  // Check if the "debug" parameter is present
  const isDebug = urlParams.get('debug') === 'true';

  //Fade in on start
  useLayoutEffect(() => {
    api.start({
      from: {
        transform: 'translateX(100%)', opacity: 0
      },
      to: {
        transform: 'translateX(0%)', opacity: 1
      },
      delay: 1000
    })
  }, []);


  //Fade out on interaction
  const handleClick = () => {
    api.start({
      from: {
        transform: 'translateX(0%)', opacity: 1
      },
      to: {
        transform: 'translateX(-100%)', opacity: 0
      },
      onResolve: () => {
        buttonHandler();
      }
    })
  }

  return (
    <div className="overlay overlay-center">

      <div className="dialog-container">
        <animated.div className="dialog-box" style={springs}>
          <img src={Bredella} alt="Bredella Lookout" />
          <p>{text}</p>
          {(!additionalMessage || isDebug) && <button className="rounded-button" style={{width: '100%'}} onClick={() => { buttonHandler() }}>{buttonLabel}</button>}
          <p style={{ fontSize: "16pt", color: "#91006c" }}>{additionalMessage}</p>

        </animated.div>
        <img src={Logo} style={{ width: '68pt', position: 'absolute', bottom: '0', right: '0'}} />

      </div>
    </div>
  );
}