import { useEffect } from "react";
import SVGIcon from "/ui/360.svg";

export default function CoachingOverlay({setWaitForCoachingOverlay})
{

  useEffect(() => {
    // Function to be called after 5 seconds
    const delayedFunction = () => {
      console.log('Function called after 5 seconds!');
      setWaitForCoachingOverlay(false);
      // Add your logic here
    };

    // Set a timeout to call the function after 5 seconds
    const timeoutId = setTimeout(delayedFunction, 6000);

    // Clear the timeout if the component is unmounted before the 5 seconds
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array ensures that the effect runs only once after initial render



    return <div className="overlay">
    <div className="bubble" style={{pointerEvents: 'auto'}} onClick={() => { setWaitForCoachingOverlay(false) }}>
      <p>Bewege dein Gerät um dich umzuschauen.</p><
        img src={SVGIcon} alt="360 degrees" style={{ width: '80pt', position: 'relative' }}></img>
        </div>
    </div>
  
}
