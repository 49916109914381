import { Canvas } from '@react-three/fiber'
import { Suspense, useEffect, useState } from 'react'

import * as THREE from 'three'
import AutoCamera from './SceneComponents/AutoCamera.jsx'

import CoachingOverlay from './LayoutComponents/CoachingOverlay.jsx'
import Menu from './LayoutComponents/Menu.jsx'
import StyledLoader from './LayoutComponents/StyledLoader.jsx'
import DeviceOrientationControlsCustom from './SceneComponents/DeviceOrientationControlsCustom.jsx'
import DirectionLightWithShadows from './SceneComponents/DirectionLightWithShadows.jsx'
import FirstPersonControlsCustom from './SceneComponents/FirstPersonControlCustom.jsx'
import LoadModel from './SceneComponents/LoadModel.jsx'
import PostFX from './SceneComponents/PostFX.jsx'
import Rendering from './SceneComponents/Rendering.jsx'
import SpeechBubbleHTML from './SceneComponents/SpeechBubbleHTML.jsx'
import TextureSky from './SceneComponents/TextureSky.jsx'
import ViewpointHTMLButton from './SceneComponents/ViewpointHTMLButton.jsx'
import Analytics from './Components/Analytics.jsx'



export function Scene({ deviceMotionEnabled, sceneURL, towerURL, interactiveSceneActive }) {

  const [zoomedIn, setZoomedIn] = useState(false);
  const [waitForFrames, setWaitForFrames] = useState(false);
  const [viewportTransitionCount, setViewportTransitionCount] = useState(0);
  const [normalizedTransition, setNormalizedTransition] = useState(0);
  const [viewpoint, setViewPoint] = useState(0);
  const [resetForward, setResetForward] = useState(() => { });
  const [waitForCoachingOverlay, setWaitForCoachingOverlay] = useState(true);
  const [currentBubble, setCurrentBubble] = useState(0)

  const setViewPointAndResetZoom = (newViewpoint) => {
    setViewPoint(newViewpoint);
    setZoomedIn(false);
  }

  useEffect(() => {
    if (normalizedTransition == 1) {
      setViewportTransitionCount(viewportTransitionCount + 1);
    }
  }, [normalizedTransition]);



  const toggleZoomLevel = () => {
    setZoomedIn(!zoomedIn);
    console.log("Zoomed true " + zoomedIn);
  }

  return <>
  <Analytics viewpoint={viewpoint} />
    <Canvas >
      <Rendering viewpoint={viewpoint} setWaitForFrames={setWaitForFrames} />
      <fogExp2 attach="fog" args={['#E7CCCC', 0.003]} />
      <TextureSky />
      <DirectionLightWithShadows />
      {viewpoint < 2 && <pointLight position={[44.7, 10.7, -39.6]} color={new THREE.Color(1, 1, 1)} intensity={12} />}
      <ambientLight intensity={2} />

      <Suspense fallback={null}><LoadModel sceneURL={towerURL} /></Suspense>
      <Suspense fallback={null}><LoadModel sceneURL={sceneURL[0]} visible={(viewpoint <= 1 || normalizedTransition != 1) && interactiveSceneActive} /></Suspense>
      <Suspense fallback={null}><LoadModel sceneURL={sceneURL[1]} visible={viewpoint == 2 && interactiveSceneActive && normalizedTransition == 1} /></Suspense>
      <Suspense fallback={null}><LoadModel sceneURL={sceneURL[2]} visible={viewpoint == 3 && interactiveSceneActive && normalizedTransition == 1} /></Suspense>

      {!interactiveSceneActive ?
        <AutoCamera />
        :
        <>
          <ViewpointHTMLButton point={[44.8, 10.9, -40.6]} text={"Zurück zum Turm"} visibleFromIndices={[1, 3]} viewPointIndex={0} setViewpoint={setViewPoint} viewpoint={viewpoint} normalizedTransition={normalizedTransition} setNormalizedTransition={setNormalizedTransition} htmlElementOffset={[0, 3, 0]} disabled={waitForCoachingOverlay} />
          <ViewpointHTMLButton point={[48.0568, 1.5, -76.7904]} text={"Hier geht es zur Entdeckungsreise"} viewPointIndex={1} visibleFromIndices={[0]} setViewpoint={setViewPoint} viewpoint={viewpoint} normalizedTransition={normalizedTransition} setNormalizedTransition={setNormalizedTransition} htmlElementOffset={[-2, -12, 5]} disabled={waitForCoachingOverlay} />
          <ViewpointHTMLButton point={[138.412, 1.5, -164.821]} text={"Zum Talbachplatz"} viewPointIndex={2} visibleFromIndices={[1, 3]} setViewpoint={setViewPoint} viewpoint={viewpoint} normalizedTransition={normalizedTransition} setNormalizedTransition={setNormalizedTransition} transitionSpeed={8000} htmlElementOffset={[0, 10, 0]} disabled={waitForCoachingOverlay} />
          <ViewpointHTMLButton point={[90.928, 1.5, -126.72]} text={"Zum Innenhof"} viewPointIndex={3} visibleFromIndices={[2]} setViewpoint={setViewPoint} viewpoint={viewpoint} normalizedTransition={normalizedTransition} setNormalizedTransition={setNormalizedTransition} transitionSpeed={5000} disabled={waitForCoachingOverlay} />
          {viewportTransitionCount != 0 && <>
            {deviceMotionEnabled ? <DeviceOrientationControlsCustom viewpoint={viewpoint} />
              :
              <FirstPersonControlsCustom setResetForward={setResetForward} />}
            {normalizedTransition == 1 && <>
              <SpeechBubbleHTML group={0} position={[48.1149, 1.8, -79.9396]} text={"Hast du Lust auf einen kleinen Rundgang durch Bredella? Gleich da drüben geht es los."} viewpoint={viewpoint} visibleFromIndices={[1]} currentBubble={currentBubble} setCurrentBubble={setCurrentBubble} />

              <SpeechBubbleHTML group={1} position={[140.361, 2, -167.715]} text={"Der Talbachplatz ist unser Treffpunkt für Jung und Alt."} viewpoint={viewpoint} visibleFromIndices={[2]} currentBubble={currentBubble} setCurrentBubble={setCurrentBubble} />
              <SpeechBubbleHTML group={2} position={[141.734, 2, -165.18]} text={"Wir haben uns in Bredella kennengelernt."} viewpoint={viewpoint} visibleFromIndices={[2]} currentBubble={currentBubble} setCurrentBubble={setCurrentBubble} />
              <SpeechBubbleHTML group={3} position={[135.792, 2, -165.746]} text={"Im Quartierbüro habe ich jemanden gefunden, der mir mit dem Computer hilft."} viewpoint={viewpoint} visibleFromIndices={[2]} currentBubble={currentBubble} setCurrentBubble={setCurrentBubble} />
              <SpeechBubbleHTML group={4} position={[136.486, 2.1, -169.104]} text={"Schnell noch einen Kaffee und dann ab auf den Zug."} viewpoint={viewpoint} visibleFromIndices={[2]} currentBubble={currentBubble} setCurrentBubble={setCurrentBubble} />
              <SpeechBubbleHTML group={5} position={[139.6, 1, -163.082]} text={"Wuff!"} viewpoint={viewpoint} visibleFromIndices={[2]} currentBubble={currentBubble} setCurrentBubble={setCurrentBubble} />

              <SpeechBubbleHTML group={6} position={[88.7785, 2.1, -125.646]} text={"Unser Innenhof ist wie eine kleine Insel mitten in der Stadt."} viewpoint={viewpoint} visibleFromIndices={[3]} currentBubble={currentBubble} setCurrentBubble={setCurrentBubble} />
              <SpeechBubbleHTML group={7} position={[91.8685, 1.6, -120.912]} text={"In Bredella sind wir mittendrin im Leben."} viewpoint={viewpoint} visibleFromIndices={[3]} currentBubble={currentBubble} setCurrentBubble={setCurrentBubble} />
              <SpeechBubbleHTML group={8} position={[88.2763, 2.1, -127.844]} text={"Hier ist meine grüne Oase vor der Haustür."} viewpoint={viewpoint} visibleFromIndices={[3]} currentBubble={currentBubble} setCurrentBubble={setCurrentBubble} />
              <SpeechBubbleHTML group={9} position={[96.126, 2, -127.318]} text={"Wenn in unserem eigenen Beet nichts wächst, ist immerhin der Markt gleich nebenan."} viewpoint={viewpoint} visibleFromIndices={[3]} currentBubble={currentBubble} setCurrentBubble={setCurrentBubble} />
              <SpeechBubbleHTML group={10} position={[93.1167, 2, -124.569]} text={"Ich kenne die Leute von nebenan. Das ist toll!"} viewpoint={viewpoint} visibleFromIndices={[3]} currentBubble={currentBubble} setCurrentBubble={setCurrentBubble} />


            </>}
          </>}
        </>
      }
    </Canvas>
    <StyledLoader />
    {waitForCoachingOverlay && interactiveSceneActive && normalizedTransition == 1 && <CoachingOverlay setWaitForCoachingOverlay={setWaitForCoachingOverlay} />}

    {viewpoint >= 0 && interactiveSceneActive && <Menu viewpoint={viewpoint} />}

  </>

}

export default Scene;