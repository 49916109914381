import { useEffect } from 'react';
import ReactGA from 'react-ga';
ReactGA.initialize('G-YMHT43VHNE');


export default function Analytics({ viewpoint }) {
    useEffect(() => {
        let pageViewPath = window.location.pathname + "viewpoint_" + viewpoint;
        ReactGA.pageview(window.location.pathname + "viewpoint_" + viewpoint);
    }, [viewpoint]);

    return null
}
